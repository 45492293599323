nav {
  background-color: #000000;
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;
  z-index: 1000;
  position: fixed;
}

a {
  color: #ffffff;
  text-decoration: none;
  padding: 0.5em;
  font-family: "Arial", serif;
  font-weight: bold;
  font-size: 24px;
  justify-content: space-evenly;
  display: block;
  
}

.login-container a {
  background-color: #000000;
  color: #ffffff;
  font-size: 14px;
}

.registrer-container a {
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
}

.button-container {
  border-color: #ffffff;
  border-style: dashed;
}

@media (max-width: 1000px) {
  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px;
  }

  .navbar-left {
    display: flex;
    align-items: center;
    padding-left: 2%;
  }

  .navbar-right {
    display: block;
    position: fixed;
    right: 50px;
    top: 12px;
  }

  .navbar-logo {
    display: block;
    justify-content: left;
    position: fixed;
    left: 50px;
    top: 10px;
  }

  .navbar-menu {
    position: absolute;
    top: 78px;
    left: 0;
    background: #000000;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .navbar-menu a {
    color: #ffffff;
    text-decoration: none;
    padding: 0.5em;
    font-family: "Arial", serif;
    font-weight: bold;
    font-size: 24px;
    justify-content: space-evenly;
    text-align: center;
  }

  .navbar-menu a:hover {
    border-bottom: none;
  }

  .navbar-menu.open {
    box-shadow: 0 10px 10px rgba(192, 192, 192, 0.5);
    max-height: 500px;
  }

  .navbar-close {
    display: block;
    cursor: pointer;
    float: right;
    margin-right: 10px;
  }

  .button-container {
    display: inline-flex;
    justify-content: space-between;
    border-radius: 40px;
    top: 20px;
    right: 60px;
    border-width: 2px;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .login-container a {
    text-align: center;
    white-space: nowrap;
    justify-content: space-between;
    display: inline-flex;
    font-size: 18px;
    border-radius: 40px 0px 0px 40px;
    border-bottom: none;
    padding: 10px;
  }

  .registrer-container a {
    background-color: #ffffff;
    color: #000000;
    display: inline-flex;
    text-align: center;
    white-space: nowrap;
    font-size: 18px;
    justify-content: space-between;
    border-radius: 0px 40px 40px 0px;
    border-bottom: none;
    border-left: solid rgb(0, 0, 0) 3px;
    padding: 10px;
  }

  .login-container a:hover {
    color: #5a5c5fdb;
    border-bottom: none;
  }

  .registrer-container a:hover {
    color: #5a5c5fdb;
    border-bottom: none;
  }
}

@media (min-width: 1000.1px) {
  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }

  .navbar-left {
    display: flex;
    align-items: center;
    padding-left: 2%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .navbar-right {
    display: none;
  }

  .navbar-logo {
    display: block;
    justify-content: left;
    position: fixed;
    left: 50px;
    top: 10px;
  }

  .navbar-menu {
    display: flex;
    align-items: right;
    justify-content: space-between;
    left: 380px;
    margin-left: -100px;
    top: 28px;
    position: fixed;
    gap: 50px;
  }

  .navbar-menu a {
    display: block;
    padding: 0;
    font-size: 2rem;
    border-bottom: solid rgb(0, 0, 0) 3px;
  }

  .navbar-menu a:hover {
    border-bottom: solid #ffffff 3px;
  }

  .navbar-icon {
    display: none;
  }

  .navbar-close {
    display: none;
  }

  .button-container {
    display: inline-flex;
    justify-content: space-between;
    position: fixed;
    border-radius: 40px;
    top: 20px;
    right: 60px;
    border-width: 2px;
    align-items: center;
  }

  .navbar-menu a + a {
    margin-left: 80px;
  }

  .login-container a {
    text-align: center;
    white-space: nowrap;
    justify-content: space-between;
    display: flex;
    font-size: 18px;
    border-radius: 40px 0px 0px 40px;
    border-bottom: none;
    padding: 10px;
    align-items: center;
  }

  .registrer-container a {
    background-color: #ffffff;
    display: flex;
    text-align: center;
    white-space: nowrap;
    font-size: 18px;
    justify-content: space-between;
    border-radius: 0px 40px 40px 0px;
    border-bottom: none;
    border-left: solid rgb(0, 0, 0) 3px;
    padding: 10px;
    align-items: center;
  }

  .login-container a:hover {
    color: #e6e5e5db;
    border-bottom: none;
  }

  .registrer-container a:hover {
    color: #575757db;
    border-bottom: none;
  }
}
