.contact-container {
    background-color: black;
    color: white;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.contact-container.visible {
    opacity: 1;
}

.contact-container h1{
    font-size: 4rem;
    margin-bottom: -10px;
}

.contact-container h2{
    font-size: 3rem;
}

.contact-container a {
    color: white;
    text-decoration: none;
}

.icon-links {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 600px;
    font-weight: bold;
    font-size: 1.4rem;
    gap: 100px;
}

.icon-container {
    text-align: center; /* Sentrer overskriften over ikonet */
}

.icon-title {
    margin-bottom: -5px; /* Legger til litt mellomrom mellom overskriften og ikonet */
}

/* Media Query for skjermer mindre enn 768px */
@media (max-width: 768px) {
    .contact-container h1 {
        font-size: 2rem;
    }
    
    .contact-container h2 {
        font-size: 1.5rem;
    }

    .icon-links {
        flex-direction: column;  /* endrer layout til kolonne for små skjermer */
        align-items: center;
        gap: 50px;
        font-size: 1rem;
    }

    .icon-container .MuiSvgIcon-root {
        font-size: 5rem !important;  /* reduserer ikonstørrelsen */
    }
}
