.homeTop {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 3vh;
  margin-top: 20px;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.homeTop.visible {
  opacity: 1;  /* Gjør elementet helt synlig når det kommer inn i visningen */
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  gap: 1vh;
  
}

.textContainer h1 {
  padding-top: 20%;
  font-size: 2.5em;
  margin-bottom: -20px;
  margin-left: 30px;
}

.textContainer p {
  font-size: 1.3em;
  justify-content: left;
  margin-left: 30px;
  margin-right: 20px;
}

.btnContainer {
  display: flex;
  justify-content: left;
  width: 100%;
  margin-top: -15px;
  margin-left: 20px;
}

.btnContainer span{
  font-size: 1.2rem;
  justify-content: center;
}

.btnContainer .arrow{
  margin-bottom: -10px;
  margin-left: 5px;
}


.textContainer button {
  font-size: 1.2em;
  padding: 10px 20px;
  background: white;
  border: none;
  color: black;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  transition: background 0.3s ease, transform 0.3s ease;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.textContainer button:hover {
  background: #f2f2f2;
  transform: scale(1.05);
}








.imageContainer {
  padding: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sideImage {
  max-height: 70vh;
  max-width: 100%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (min-width: 600.1px) {
  .homeTop {
    flex-direction: row;
    padding-bottom: 0;
  }

  .textContainer {
    max-width: 600px;
    text-align: left;
    align-items: flex-start;
    margin-left: 5vw;
    align-items: flex-start;
    margin-top: -45vh;
  }
  .textContainer button span {
    display: flex;
    align-items: center;
    gap: 10px; /* Legger til litt mellomrom mellom teksten og ikonet */
}

.textContainer h1{
  font-size: 3rem;
  margin-bottom: -20px;
}
.btnContainer span{
  font-size: 1.4rem;
}


.textContainer p{
  font-size: 2rem;
}

  .btnContainer {
    align-self: flex-start;
    justify-content: left;
    margin-top: -20px;
  }

  .btnContainer .arrow{
    margin-bottom: 0px;
    font-size: 1.5rem;
  }

  .imageContainer {
    padding: 0;
  }

  .textContainer .changing-text {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1250px) {
  .homeTop {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120vh;
  }
  
  .textContainer {
    margin-top: -100px; /* Resetter margin-top til 0, juster denne verdien etter behov */
    align-items: center;
    text-align: center;
  }

  .btnContainer{
    justify-content: center;
  }
  
  .imageContainer {
    margin-top: 20px; /* Legger til litt mellomrom mellom teksten og bildet, juster denne verdien etter behov */
  }
}

@media screen and (max-width: 600px) {
  .homeTop {
    height: 90vh;
  }
  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    gap: 1vh;
    margin-top: -100px;
  }
  
  .textContainer h1 {
    padding-top: 20%;
    font-size: 2.5em;
    margin-bottom: -20px;
    margin-left: 30px;
  }
  
  .textContainer p {
    font-size: 1.3em;
    justify-content: left;
    margin-left: 30px;
  }
  
  .btnContainer {
    display: flex;
    justify-content: left;
    width: 100%;
    margin-top: -15px;
    margin-left: 20px;
  }
  .imageContainer {
    margin-top: 0px; /* Legger til litt mellomrom mellom teksten og bildet, juster denne verdien etter behov */
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .homeTop{
    height: 150vh;
  }
  .imageContainer{
    margin-bottom: 50px; 
  }
}
