/* Generell styling */

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  color: white;
  padding: 20px;
  margin-bottom: 30px;
}

.projects-container h1 {
  font-size: 2.8rem;
  text-align: left;
  border-bottom: 3px solid white;
  margin: 0px 20px;
  margin-right: 30px;
  margin-bottom: -20px;
}

.projects-container h2 {
  font-size: 2rem;
  margin-bottom: -10px;
}

.projects-container a {
  text-decoration: underline;
  color: inherit;
}

.section {
  width: 100%;
  max-width: 1200px;
  margin: 20px 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.section.visible {
  opacity: 1;
}

.project-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-content .text {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
}

.project-image img {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: contain;
}

.project-link {
  margin-left: -8px;
  font-size: 1.2rem;
}

/* Spesifikk styling for skjermer som er større enn 1200px */

@media (min-width: 1200px) {
  .project-content {
      flex-direction: row;
      justify-content: space-between;
  }

  .projects-container h1 {
      text-align: center;
      margin-bottom: 40px;
  }

  .projects-container h2 {
    width: 800px;
}

  .project-content .text {
    width: 45%;  /* Justert bredde for bedre plassering */
    text-align: left;
    max-width: 500px;
    margin-left: 40px; /* Legg til margin til venstre */
    margin-top: -200px;
}

  .project-image img{
    border-radius: 15px;
}

  .section {
      margin-bottom: 50px;
  }

  .section:nth-child(even) .project-content {
      flex-direction: row-reverse;
  }

  .section:nth-child(even) .project-content .text {
    margin-left: 0;
    margin-right: 40px; /* Bytt margin til høyre for partall seksjoner */
}

.section:nth-child(odd) .project-content .text {
  margin-left: 50px;
}

  .project-image-0 {
      margin-right: 150px;
  }

  .project-image-1 {
    margin-left: 40px;
}

  .project-image-2 {
    margin-right: 20px;
}

  .project-image-3 {
      margin-left: 100px;
  }
}

/* Spesifikk styling for skjermer som er mindre enn 1200px */

@media (max-width: 1199.9px) {
  .project-content {
      flex-direction: column;
      align-items: center;
  }

  .project-content .text {
      margin-bottom: 15px;
      text-align: left;
      max-width: 600px;
      margin-left: 15px;
  }

  .project-image img {
      width: 100%;
      border-radius: 15px;
      margin: 0;
  }
}
